import React, { Component } from "react";
import Emoji from "react-emoji-render";

export class Services extends Component {
  render() {
    return (
      <div id="services" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Un point technique</h2>
            <p>
              <Emoji text="Fetchy est une application entièrement faite maison, codée avec ♥️ entre deux nuits de garde. C'est du 100% vétérinaire, 50% Alfort - 50% Lyon."/>
            </p>
          </div>
          <div className="row">
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div  key={`${d.name}-${i}`} className="col-md-4">
                    {" "}
                    <i className={d.icon}></i>
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
