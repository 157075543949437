import React, { useState, useEffect } from "react";
import useGoogleSheets from 'use-google-sheets';
import Tabletop from "tabletop";
import {GoogleSpreadsheet} from "google-spreadsheet"

export default function Testimonials() {
  const creds = {
    "type": "service_account",
    "project_id": "high-fuze-126513",
    "private_key_id": "7915b8b8bbd3a731dc316a409377b4a2e21e9bdf",
    "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDQAvvAKt9x3KdL\nGwtLJ20oCD4XTaNjeKWkfof2sI37LY/71jHcyJrenOoblMAHSf5KT4zZgSBVScNV\nDvbVDFDNZXOrhcuy0Afxfgm2K20m1u7s51euNYsiKbKcKUsquhkzjy4Mx8H2i6z6\nONydadbdQNwbTHiZ6Z6lgh63fAExf7Sunt7g9Ffdabf5s7bcdp18AWO2o686gLDg\nhIo0pJyj/+gGnfTRgH6ypIKAQp8VTQxYqhFoPjAJ867JLgwvXa3bR9Guivo7EiLa\n/B90pKiXdDHpLhpzWck9BZb5D5Prd+u+rNr9PjhZZPFV7eUO/2G7uW19x/EzmxNk\npPWE8NFRAgMBAAECggEACKRn479hDbWMCmhXyYbpKtdFOlaHYfGOn9bO9ms2I7gS\no5t6vyn8sm0k1kHRnmvoiAG/Zkw0wb5hD0Au9dtY++BwOn5CU1rmMMmBBOPPsuQN\n3knFTSkpCO553uTIHFp9HUG6LGp1ohuCM6b+b+U7rPEaw/qWU/GBt4dZ5Q5en7kG\n+99WMGYQOUOk+2CHtiO4Otp6s3d3acBhaGyg7xT9qBTji4wm+/1YC55/8+y54p3V\n1DI2Nzja0VD/RETN34WLKwb6j7U1T9Me2MwjdTQCRu3IP25d6xN8W8wthVGvwvyi\ntHcSCQaKqwP2a0KqDpnq+AjV9MIKYIifNqGgglx1jQKBgQD6Zs395RALl+Jgrx1M\nQpBtbGuOa63ZWGDAIEpahUFgo2CoGZpw/so+OAdRf0oJBMOqI7xQ24Yf+OXgfecc\nUk9H0IFI8dICjjdNkyYu3gofk2Jz7H5B4MOQiI//wcwZuQ7jRqUpcvoa3CUTCHcC\n5SdExlNmxsY9ouysQl2tLc/8pQKBgQDUqY5pM0Od/FjgiKTNawqz9BV1gt38F0sO\nhYcX2HZXE+y0ce3HiB+27Ew3IuBWdOZwgkFxMcDpMnZVTKEG4IAe00klhlcLccBY\njZqeMNJYyjkhAu7fLGRvY1TdmBysOOhCsxX1/IUyIpuCobw9XrV0rZ+3nf5AmKZE\nCnus9fLGPQKBgQC2ljgmptzoVzwvHAHVFcSSxbg4BbSjA5qo/eK51ukxOnDEosbH\nFkMK03giqRqnMN4sNhqfKzPGJIwbAjN/p1VAHH3pXsuwKVuSKXZyPl5LYAEuXohC\nKU7xGWNHL11QdGyytVTBwKE27Mfr9QQzDD1yHNu72b28PA0wHsSH/xvmMQKBgQCO\nTlQk45AY1TdoMSgO6dVErE37z7HaMoAgXIwVEd3G0WdxLFfQUK/gSWmK78StNhbK\nLO+73m5X3lO/KsMKhgpWFk75VFdFKj5rQ3624ravgcsO4xaTsvIWc6LjkhmFyaLq\nl0pXzD4ZWX00wiPj8U99n32kco4kDcIc/+LYz9BAhQKBgQDCbs1meEha8WttaKPU\neiXIzUFavxWKCQXTOYCxtwD9btgfKAz+LujYumA3oi+mxAymsdtJZAgm5FnO6Obn\neyon0uWFLkiwdt9Z3U7N9tpBB6z1B4t/YWKtjzljh7RV+AEHtp7QveRNgE4QfXsG\nZnaJfBcDws3uRk6wZ+krdZXP+A==\n-----END PRIVATE KEY-----\n",
    "client_email": "high-fuze-126513@appspot.gserviceaccount.com",
    "client_id": "",
    "auth_uri": "https://accounts.google.com/o/oauth2/auth",
    "token_uri": "https://oauth2.googleapis.com/token",
    "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
    "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/high-fuze-126513%40appspot.gserviceaccount.com"
  }

  const [rows, setRows] = useState([]);
  console.log(rows)

    async function getSheet(){
      //1.
      const doc = new GoogleSpreadsheet('1YALcPv_J3_CQecgm6svyjJzQh71fK2phRBxJTVCv6vw');

      //2.
      await doc.useServiceAccountAuth({
        client_email: creds.client_email,
        private_key: creds.private_key,
      });

      //3.
      await doc.loadInfo();
      const sheet = doc.sheetsByIndex[0]; //get 1st worksheet
      const rows = await sheet.getRows(); //get all its rows

      //4.
      setRows(rows);
    }

  useEffect(() => {
    getSheet()
  }, []);


  return (
      <div id="testimonials">
        <div className="container">
          <div className="section-title text-center">
            <h2>Ce qu'en pensent nos consoeurs et confrères</h2>
          </div>
          <div className="row">
            {rows
              ? rows.map((d, i) => (
                  <div key={`${d.Name}-${i}`} className="col-md-4">
                    <div className="testimonial">
                      <div className="testimonial-content">
                        <p>"{d.Comment}"</p>
                        <div style={{color: '#282c34'}} className="testimonial-meta"> {d.Name} - {d.Statut} </div>
                        <p style={{fontSize: '11px',fontStyle:'italic'}}>{d.Practise}</p>
                      </div>
                    </div>
                      </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  }

