import Emoji from "react-emoji-render";
import React, {useState, useEffect, useContext} from "react";
import { useForm, Controller } from "react-hook-form";
import useFetch from "use-http";
import ClipLoader from "react-spinners/ClipLoader";
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select'
import { Autocomplete } from '@material-ui/lab';
import {
    FormControl, Box, Grid, Container
} from '@material-ui/core';
import {Check} from '@material-ui/icons';


const NewsLetter = (props) => {
    const {register, errors, handleSubmit, watch,control} = useForm();
    //const url = 'http://0.0.0.0:8000'
    const url = 'https://lama.fetchy.vet'
    const onSubmitNewsLetter = data => sendMessage('/api/user/newsletter/', data);
    const [request, response] = useFetch(url);
    const [modalLegals, updateModaleLegals] = useState(false)
    const optionsPublic = [{ value: "VET", name: "Vétérinaire" },
            { value: "ASV", name: "ASV" },
            { value: "PRO", name: "Para-professionnel de la santé animal" },
            { value: "PART", name: "Particulier" }]

    async function sendMessage(url, data) {
        let bodyFormData = new FormData();
        bodyFormData.append('first_name', data['FirstName']);
        bodyFormData.append('last_name', data['LastName']);
        bodyFormData.append('email', data['Email']);
        bodyFormData.append('category', data['Category'].value);
        const messageSend = await request.post(url, bodyFormData)
        if (response.ok) console.log("SUCCESS")
    }

    const handleClose = () => {
        updateModaleLegals(false);
    };
    let submit_buton = <button type="submit" className="btn btn-custom btn-lg">
        Je m'abonne !
    </button>

    if (response.ok) submit_buton = <button className="btn btn-custom btn-lg">Bien noté <Check />
    </button>
    if (request.loading) submit_buton = <ClipLoader
        size={30}
        color={"#00204f"}
        loading={request.loading}
    />

    return (
        <div id="newsletter" className="text-center">
            <div className="container">
                <div className="section-title">
                    <h2>Le Newsletter de Charlotte</h2>
                    <p>
                        <Emoji
                            text="Tenez vous informé des dernières actualités nutritionnelles à travers une newsletter mensuelle rédigée avec la patte attentive de Charlotte!"/>
                    </p>
                </div>
                <form onSubmit={handleSubmit(onSubmitNewsLetter)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                            <TextField
                                    {...register('FirstName')}
                                    type="text"
                                    id="firstname"
                                    margin="normal"
                                    variant="outlined"
                                    required
                                    style={{ 'backgroundColor': '#ffff', 'fontSize':20 }}
                                    label="Prénom"
                                    required="required" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                            <TextField
                                    {...register('LastName')}
                                    type="text"
                                    id="lastname"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    style={{ 'backgroundColor': '#ffff', 'fontSize':'20px' }}
                                    label="Nom"
                                    required="required" />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                                <FormControl fullWidth>
                                <TextField
                                    {...register('Email')}
                                    type="email"
                                    id="email"
                                    variant="outlined"
                                    required
                                    style={{ 'backgroundColor': '#ffff', 'fontSize':'20px' }}
                                    label="Email"
                                    required="required" />
                                </FormControl>
                            </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                                <FormControl fullWidth>
                                <Controller
                                        id="Category"
                                        labelId="Category-label"
                                        control={control}
                                        name="Category"
                                        rules={{ required: true }}
                                        render={({ field: { onChange, value } }) => (
                                            <Autocomplete
                                                onChange={(event, item) => {
                                                    onChange(item);
                                                }}
                                                value={value}
                                                options={optionsPublic}
                                                getOptionLabel={(item) => {
                                                    if (item) return item.name;
                                                    return '';
                                                }}
                                                getOptionSelected={(option) => option === value.name}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Je suis ..."
                                                        margin="normal"
                                                        variant="outlined"
                                                        required
                                                        style={{ 'backgroundColor': '#ffff', 'fontSize':'20px' }}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                    </Grid>
                        {submit_buton}
                </form>
            </div>
        </div>
    );
}



export default NewsLetter;


