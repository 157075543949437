import Emoji from "react-emoji-render";
import React, {useState, useEffect, useContext} from "react";
import { useForm } from "react-hook-form";
import useFetch from "use-http";
import ClipLoader from "react-spinners/ClipLoader";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const onSubmit = data => console.log(data);

const Contact = (props) => {
  const { register, errors, handleSubmit,watch } = useForm();
  console.log(watch("name"))
  //const url = 'http://127.0.0.1:8000'
  const url='https://lama.fetchy.vet'
  const onSubmit = data => sendMessage('/api/user/send-contact-email',data);
  const [request, response] = useFetch(url);
  const [modalLegals,updateModaleLegals]=useState(false)

  async function sendMessage(url,data) {
      let bodyFormData = new FormData();
      bodyFormData.append('name', data['name']);
      bodyFormData.append('message_sent', data['message_sent']);
      bodyFormData.append('email', data['email']);
      const messageSend = await request.post(url,bodyFormData)
      if (response.ok) console.log("SUCCESS")
  }

    const handleClose = () => {
    updateModaleLegals(false);
    };
   let submit_buton = <button type="submit" className="btn btn-custom btn-lg">
          Faire parvenir ma missive
      </button>

    if (response.ok) submit_buton=<p>Bien reçu ✓ on vous répond aussi vite que possible!</p>
    if (request.loading) submit_buton=<ClipLoader
          size={30}
          color={"#fff"}
          loading={request.loading}
        />

   return (
     <div>
       <div id="contact">
         <div className="container">
           <div className="col-md-8">
             <div className="row">
               <div className="section-title">
                 <h2>Mot Doux</h2>
                 <p>
                   Une remarque, un commentaire, une critique non constructive? Laisse nous tes coordonnées et ta prose ci dessous, nous tâcherons de trouver du temps pour te répondre.
                 </p>
               </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                 <div className="row">
                   <div className="col-md-6">
                     <div className="form-group">
                       <input
                         {...register('name')}
                         type="text"
                         id="name"
                         className="form-control"
                         placeholder="Nom"
                         required="required" />
                       <p className="help-block text-danger"></p>
                     </div>
                   </div>
                   <div className="col-md-6">
                     <div className="form-group">
                       <input
                         {...register('email')}
                         type="email"
                         id="email"
                         className="form-control"
                         placeholder="Email"
                         required="required" />
                       <p className="help-block text-danger"></p>
                     </div>
                   </div>
                 </div>
                 <div className="form-group">
                   <textarea
                     {...register('message_sent')}
                     id="message"
                     className="form-control"
                     rows="4"
                     placeholder="Message"
                     required></textarea>
                 </div>
{submit_buton}
               </form>
             </div>
           </div>
           <div className="col-md-3 col-md-offset-1 contact-info">
             <div className="contact-item">
               <h3>Contact</h3>
               <p>
                 <span>
                   <i className="fa fa-map-marker"></i> Adresse
                 </span>
                 {props.data ? props.data.address : "loading"}
               </p>
             </div>
             <div className="contact-item">
               <p>
                 <span>
                   <i className="fa fa-envelope-o"></i> Email
                 </span>{" "}
                 {props.data ? props.data.email : "loading"}
               </p>
             </div>
           </div>
           <div className="col-md-12">
             <div className="row">
               <div className="social">
                 <ul>
                   <li>
                     <a
                       href={props.data ? props.data.facebook : "/"}
                     >
                       <i className="fa fa-facebook"></i>
                     </a>
                   </li>
                   <li>
                     <a href={props.data ? props.data.twitter : "/"}>
                       <i className="fa fa-twitter"></i>
                     </a>
                   </li>
                   <li>
                     <a href={props.data ? props.data.instagram : "/"}>
                       <i className="fa fa-instagram"></i>
                     </a>
                   </li>
                   <li>
                     <a href={props.data ? props.data.podcast : "/"}>
                       <i className="fa fa-headphones"></i>
                     </a>
                   </li>
                 </ul>
                   <button className="btn btn-custom btn-small">
         Mentions Légales
     </button>
               </div>
             </div>
           </div>
         </div>
       </div>
       <div id="footer">
         <div className="container text-center">
            {'Copyright © '}
       The Stable Company <Emoji text=" 🐶 ⚔️ 🦙   "/>
     { new Date().getFullYear()}
     {'.'}
         </div>
       </div>
         <Dialog
       open={modalLegals}
       onClose={handleClose}
       aria-labelledby="alert-dialog-title"
       aria-describedby="alert-dialog-description"
     >
       <DialogTitle id="alert-dialog-title">Mentions Légales</DialogTitle>
       <DialogContent>
         <DialogContentText id="alert-dialog-description">
           Coucou les connards
         </DialogContentText>
       </DialogContent>

     </Dialog>

     </div>
   );
  }


export default Contact;